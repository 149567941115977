import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Slider from "react-slick";

const App = () => {
  var galleryImages = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };
  var shopsImages = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };
  var gallery2Images = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="App p-0">
      <div className="navbar-main bg-white">
        <div className="container-fluid">
          <div class="container">
            <div className="row">
              <nav className="navbar navbar-expand-lg ms-auto py-3">
                <div className="col-2 col-sm-1 col-md-2 d-block d-sm-block d-md-block d-lg-none">
                  <button
                    class="btn btn-outline-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#side-navbar"
                    type="button"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>
                </div>

                <div className="col col-sm-3 col-md-4 col-lg-1 ">
                  <a class="navbar-brand" href="#">
                    <img className="img-fluid" src="./assets/logo.webp" />
                  </a>
                </div>

                <div className="col-lg-7 col-xl-6 d-none d-sm-none d-md-none d-lg-block">
                  <div
                    class="collapse navbar-collapse justify-content-end"
                    id="navbarNav"
                  >
                    <ul class="navbar-nav align-items-center">
                      <li class="nav-item main-nav-link px-4">
                        <a
                          class="nav-link active"
                          aria-current="page"
                          href="#properties"
                        >
                          Available Property
                        </a>
                      </li>
                      <li class="nav-item main-nav-link px-4">
                        <a class="nav-link" href="#facilites">
                          Facilities
                        </a>
                      </li>
                      <li class="nav-item main-nav-link px-4">
                        <a class="nav-link " href="#gallery">
                          Gallery
                        </a>
                      </li>
                      <li class="nav-item main-nav-link ps-5">
                        {/* <button className="main-cta-button">021-111 333 333</button> */}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-8 col-sm-6 col-md-6 col-lg-4 col-xl-5 text-end">
                  {/* <a href="#">
                    <img className="img-fluid" src="./assets/insta.webp" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/people/Olxpropertycom/61551832779617/"
                  >
                    <img
                      className="img-fluid px-3"
                      src="./assets/facebook.webp"
                    />
                  </a> */}
                  <button className="main-cta-button">
                    <a
                      href="tel:03352300093"
                      className="text-decoration-none text-dark"
                    >
                      <img
                        src="./assets/call-calling.svg"
                        className="img-fluid pe-2"
                      ></img>
                      0335 2300093
                    </a>
                  </button>
                  <a
                    href="https://wa.me/03352300093"
                    className="whatsapp-btn fw-bold fs-3 text-decoration-none text-dark"
                    target="_blank"
                  >
                    <img
                      src="./assets/Whatsapp.svg"
                      className="img-fluid pe-2"
                    ></img>
                    <span className="d-none d-sm-none d-md-none d-lg-none d-xl-inline-block">
                      WhatsApp
                    </span>
                  </a>
                </div>
                <div className="col-12 d-block d-sm-block d-md-block d-lg-none">
                  <div class="offcanvas offcanvas-start" id="side-navbar">
                    <div class="offcanvas-header">
                      <a class="navbar-brand" href="#">
                        <img className="img-fluid" src="./assets/logo.webp" />
                      </a>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="offcanvas"
                      ></button>
                    </div>
                    <div class="offcanvas-body">
                      <ul class="navbar-nav ms-auto">
                        <li class="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <a
                            class="nav-link active"
                            aria-current="page"
                            href="#"
                          >
                            Available Property
                          </a>
                        </li>
                        <li class="nav-item main-nav-link border border-top-0 border-start-0 border-end-0">
                          <a class="nav-link" href="#">
                            Facilities
                          </a>
                        </li>
                        <li class="nav-item main-nav-linkborder border-top-0 border-start-0 border-end-0">
                          <a class="nav-link " href="#">
                            Gallery
                          </a>
                        </li>
                        <li class="nav-item main-nav-link ps-5">
                          {/* <button className="main-cta-button">021-111 333 333</button> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-main">
        <div id="demo" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#demo"
              data-bs-slide-to="0"
              class="active"
            ></button>
            <button
              type="button"
              data-bs-target="#demo"
              data-bs-slide-to="1"
            ></button>
          </div>

          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="./assets/Banner2.webp"
                alt="slide-2"
                className="w-100 img-fluid d-block"
              />
              {/* <div class="carousel-caption hero-section-content">
                <h2 className="hero-section-heading main-yellow-color-text p-0 m-0">
                  786{" "}
                </h2>
                <h2 className="hero-section-heading text-white">
                  Center Quetta
                </h2>
                <h2 className="hero-section-pargraph">
                  A Gateway To Luxury Living
                </h2>
              </div> */}
            </div>
            <div class="carousel-item">
              <img
                className="w-100 img-fluid d-block"
                src="./assets/Banner1.webp"
                alt="slide-1"
              />
              {/* <div class="carousel-caption hero-section-content">
                <h2 className="hero-section-heading main-yellow-color-text p-0 m-0">
                  786{" "}
                </h2>
                <h2 className="hero-section-heading text-white">
                  Center Quetta
                </h2>
                <h2 className="hero-section-pargraph">
                  Building Dreams Brick By Brick
                </h2>
              </div> */}
            </div>
            {/* <div class="carousel-item">
              <img
                src="./assets/slide-3.webp"
                alt="slide-3"
                className="w-100 img-fluid d-block"
              />
              <div class="carousel-caption hero-section-content">
                <h2 className="hero-section-heading main-yellow-color-text p-0 m-0">
                  786{" "}
                </h2>
                <h2 className="hero-section-heading text-white">
                  Center Quetta
                </h2>
                <h2 className="hero-section-pargraph">
                  Your Next Home Is Here
                </h2>
              </div>
            </div> */}
          </div>

          {/* <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#demo"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon"></span>
          </button> */}
        </div>
      </div>
      {/* <div className="banner-main">
        <div className="banner-section">
          <div className="container px-lg-5">
            <div className="hero-section-content ">
              <div>
                <div className="row py-5">
                  <div className="col-sm-12 col-md-12 col-lg-8 pt-4">
                    <h2 className="hero-section-heading main-yellow-color-text p-0 m-0">
                      OLX{" "}
                    </h2>
                    <h2 className="hero-section-heading text-white">
                      Property
                    </h2>
                    <h2 className="hero-section-pargraph">
                      What Does 786 Center in Quetta Have in Store for
                      Investors?
                    </h2>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-4 pt-xl-5">
                    <div className="contact-card-main mt-lg-5">
                      <div className="hero-section-contact bg-white py-4 px-5">
                        <h5 className="title">Get in touch with us!</h5>
                        <a
                          className="text-decoration-none text-dark"
                          href="tel:03333186789"
                        >
                          <div className="d-flex contact-info pt-4">
                            <div className="img">
                              <img
                                className="img-fluid"
                                src="./assets/check-yellow.webp"
                              />
                            </div>
                            <div className=" px-4">
                              <h5 className="m-0 fw-bold">Naveed</h5>
                              <p>0333 3186789</p>
                            </div>
                          </div>
                        </a>
                        <a
                          className="text-decoration-none text-dark"
                          href="tel:03333186789"
                        >
                          <div className="d-flex contact-info pt-2">
                            <div className="img">
                              <img
                                className="img-fluid"
                                src="./assets/check-yellow.webp"
                              />
                            </div>
                            <div className=" px-4">
                              <h5 className="m-0 fw-bold">Danial</h5>
                              <p>0332 2343420</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container py-5 services">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg">
            <div className="service p-4">
              <h4 className="fw-bold">Parking</h4>
              <p className="dim-text m-0">Space for Residents</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg  ">
            <div className="service text-left p-4">
              <h4 className="fw-bold">Utility Connections</h4>
              <p className="dim-text m-0">Preinstalled Connections</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-2  ">
            <div className="service p-4">
              <h4 className="fw-bold">24/7</h4>
              <p className="dim-text m-0">Water Supply</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg ">
            <div className="service p-4">
              <h4 className="fw-bold">Transport</h4>
              <p className="dim-text m-0">Nearby Transport Facilities</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3  ">
            <div className="service p-4">
              <h4 className="fw-bold">Earthquake Resistant</h4>
              <p className="dim-text m-0">An Earthquake Resistant Structure</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container investment-section" id="properties">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
            <img
              className="img-fluid side-img"
              src="./assets/apartments.webp"
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
            <div className="py-5 ps-xl-5 pe-xl-4">
              <h2 className="section-title">Bismillah (786) Apartments</h2>
              <p>
                In the heart of Quetta City, Bismillah (786) Center Quetta
                offers the perfect blend of convenience and comfort nestled
                within a serene and family-oriented area, just a 15-minute drive
                from the airport.
              </p>
              <p>
                Our luxurious apartments are built on 650 square feet, offering
                spacious rooms, a modern lounge, a sleek kitchen, and ample
                parking for the residents.
              </p>
              <h4 className="pt-2">Features & Facilities</h4>
              <ul className="ps-0">
                <li>
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  Ample Parking Space for Residents
                </li>
                <li>
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  Preinstalled Utility Connections
                </li>
                <li>
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  24/7 Water Supply
                </li>
                <li>
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  Availability of Nearby Transport Facilities
                </li>
                <li>
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  A Gated Community
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container gallery-section mb-5 pb-5" id="gallery">
        <h2 className="section-title text-center py-3">Apartment Images</h2>
        <Slider {...galleryImages} className="align-items-center">
          <div className="swiper-slide">
            <img alt="" className="img-fluid" src="./assets/apart1.webp" />
          </div>

          <div className="swiper-slide">
            <img alt="" className="img-fluid" src="./assets/apart2.webp" />
          </div>

          <div className="swiper-slide">
            <img alt="" className="img-fluid" src="./assets/apart3.webp" />
          </div>

          <div className="swiper-slide">
            <img alt="" className="img-fluid" src="./assets/apart4.webp" />
          </div>

          <div className="swiper-slide">
            <img alt="" className="img-fluid" src="./assets/apart5.webp" />
          </div>
          <div className="swiper-slide">
            <img alt="" className="img-fluid" src="./assets/apart6.webp" />
          </div>
        </Slider>
      </div>
      <div className="shops-main pt-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
              <div className="pt-5 pe-lg-5">
                <h2 className="section-title position-relative">
                  Bismillah (786) Shops
                </h2>
                <p>
                  Approved by the Quetta Development Authority (QDA), Bismillah
                  (786) Center takes immense pride in our seamless and
                  comprehensive construction process
                </p>
                <p>
                  Shops on the best location of Quetta adjacent to 150sft wide
                  CPEC road /new sabzal road. Very affordable price bracket so
                  hurry up only 2 shops left
                </p>
                <h4 className="pt-lg-4">Features & Facilities</h4>
                <ul className="ps-0">
                  <li>
                    <img
                      className="img-fluid px-2"
                      src="./assets/check-yellow.webp"
                    />
                    Best Location, CPEC Road (New Sabzal Road)
                  </li>
                  <li>
                    <img
                      className="img-fluid px-2"
                      src="./assets/check-yellow.webp"
                    />
                    150 sft Wide
                  </li>
                  <li>
                    <img
                      className="img-fluid px-2"
                      src="./assets/check-yellow.webp"
                    />
                    Preinstalled Utility Connections
                  </li>
                  <li>
                    <img
                      className="img-fluid px-2"
                      src="./assets/check-yellow.webp"
                    />
                    24/7 Water Supply
                  </li>
                  <li>
                    <img
                      className="img-fluid px-2"
                      src="./assets/check-yellow.webp"
                    />
                    An Earthquake Resistant Structure
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
              <img
                className="img-fluid side-img"
                src="./assets/shops-main.webp"
              />
            </div>
          </div>
        </div>
        <div className="container shops-gallery py-5">
          <h2 className="section-title text-center py-3">Shop Images</h2>

          <Slider {...shopsImages} className="align-items-center">
            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/shop1.webp" />
            </div>

            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/shop2.webp" />
            </div>

            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/shop3.webp" />
            </div>

            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/shop4.webp" />
            </div>
          </Slider>
        </div>
      </div>
      <div className="location-main position-relative mt-5">
        <div className="container">
          <h2 className="section-title text-center">
            Bismillah (786) Center Location
          </h2>
          <div className="row">
            <p className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8 px-xl-4 mx-auto text-center">
              We have listed some of the key landmarks of Quetta including
              educational institutions, hospitals and sports complexes, and
              their respective distances from Bismillah (786) Center for your
              reference. Take a look!
            </p>
          </div>

          <div className="location-img text-center d-none d-md-none d-lg-block">
            <img
              src="./assets/locations.webp"
              className="img-fluid w-100"
            ></img>
          </div>
          <div className="d-block d-md-block d-lg-none">
            <div className="text-center">
              <img className="img-fluid" src="./assets/786center.webp"></img>
            </div>
            <ul className="locations ps-0">
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Benazir Flyover: 5-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Civil Secretariat: 5-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Kidney Center Hospital: 5-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Pak Turk School: 3-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Bolan Medical College: 5-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Beaconhouse School: 10-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                City School: 10-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Ayub Stadium: 10-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Civil Hospital, Quetta: 10-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Serena Hotel: 10-minute drive
              </li>
              <li>
                <img
                  className="img-fluid px-2"
                  src="./assets/location-pin.webp"
                />
                Railway Station: 10-minute drive
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-white px-0" id="contruction">
        <div className="container facilities-section py-lg-5">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
              <div className="pt-5">
                <h2 className="section-title position-relative">
                  Bismillah (786) Center Actual Site
                </h2>
                <p>
                  Approved by the Quetta Development Authority (QDA), 786 Center
                  takes immense pride in our seamless and comprehensive
                  construction process.
                </p>
                <p>
                  Each project is constructed using top construction materials,
                  including high-strength reinforced concrete and steel, chosen
                  to ensure both structural resilience and earthquake
                  resistance.
                </p>
                <h4 className="pt-lg-4">Features</h4>
                <ul>
                  <li>
                    <img
                      className="img-fluid px-2"
                      src="./assets/check-yellow.webp"
                    />
                    Approved by QDA
                  </li>
                  <li>
                    <img
                      className="img-fluid px-2"
                      src="./assets/check-yellow.webp"
                    />
                    Sustainable Materials
                  </li>
                  <li>
                    <img
                      className="img-fluid px-2"
                      src="./assets/check-yellow.webp"
                    />
                    Quality Control
                  </li>
                  <li>
                    <img
                      className="img-fluid px-2"
                      src="./assets/check-yellow.webp"
                    />
                    Earthquake Resistant Structure
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
              <img
                className="img-fluid side-img"
                src="./assets/contruction.webp"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-section bg-white pb-5">
        <div className="container  mb-5">
          <h2 className="section-title text-center py-3">Showcase</h2>
          <Slider {...gallery2Images} className="align-items-center">
            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/site1.webp" />
            </div>

            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/site2.webp" />
            </div>

            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/site3.webp" />
            </div>

            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/site4.webp" />
            </div>

            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/site5.webp" />
            </div>
            <div className="swiper-slide">
              <img alt="" className="img-fluid" src="./assets/site6.webp" />
            </div>
          </Slider>
        </div>
      </div>
      <div className="facilities-section bg py-5" id="facilites">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-7 pe-lg-5">
              <div className="pt-5 text-white">
                <h2 className="section-title position-relative">
                  Facilities and Amenities
                </h2>
                <p className="text-white">
                  At 786 Center, we blend modern amenities with security and
                  accessibility and offer a modern lifestyle enriched with
                  essential conveniences.
                </p>
                <p className="text-white">
                  It is a gated community and has ample parking, preinstalled
                  utilities, a 24/7 water supply, and an earthquake-resistant
                  structure, ensuring seamless living while prioritizing your
                  safety.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-5">
              <ul className="pt-5 ps-lg-5">
                <li className="text-white">
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  Gated Community
                </li>
                <li className="text-white">
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  Preinstalled Utility Connections
                </li>
                <li className="text-white">
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  Public Transport Availability
                </li>
                <li className="text-white">
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  Parking Space for Residents
                </li>
                <li className="text-white">
                  <img
                    className="img-fluid px-2"
                    src="./assets/check-yellow.webp"
                  />
                  Earthquake Resistant Structure
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-white">
        <div className="container newsLetter p-5">
          <h2 className="text-center">Get Our Newsletter</h2>
          <div className="row">
            <div className=" col-xs-12 col-sm-12 col-md-10 col-lg-7 col-xl-6 mx-auto p-2">
              <div className="contact-box text-center">
                <form>
                  <input
                    type="email"
                    required
                    className="fomr-control border-0"
                    placeholder="Enter your email address"
                  />{" "}
                  <button
                    type="submit"
                    className="subscribe-btn d-none d-sm-inline-block"
                  >
                    Subscribe
                    <img
                      className="img-fluid px-2"
                      src="./assets/keyboard-arrow-right.svg"
                    />
                  </button>
                </form>
              </div>
              <div className="text-center d-flex justify-content-center">
                <button
                  type="submit"
                  className="subscribe-btn d-block d-sm-none mt-2"
                >
                  Subscribe
                  <img
                    className="img-fluid px-2"
                    src="./assets/keyboard-arrow-right.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <footer className=" p-0 m-0">
        <div className="container-fluid py-3">
          <h2 className="p-0 m-0 text-white py-5 text-center">
            Make your dreams a{" "}
            <span className="main-yellow-color-text">reality</span>
          </h2>
          <hr />

          <div className="container">
            <div className="links-section">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <img
                    src="./assets/logo-white.webp"
                    className="img-fluid"
                  ></img>
                  <div className="py-3">
                    <div className="pt-2">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/people/Olxpropertycom/61551832779617/"
                      >
                        <img
                          className="img-fluid px-3"
                          src="./assets/facebook-white.webp"
                        ></img>
                      </a>
                      {/* <img
                        className="img-fluid px-3"
                        src="./assets/insta-white.webp"
                      ></img> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <h6>Quick Links</h6>
                  <a
                    href="#properties"
                    className="d-block pt-2 text-decoration-none"
                  >
                    Bismillah Center Apartments
                  </a>
                  <a
                    href="#contruction"
                    className="d-block pt-2 text-decoration-none"
                  >
                    Bismillah Construction Work
                  </a>
                  <a
                    href="#facilities"
                    className="d-block pt-2 text-decoration-none"
                  >
                    Facilities and Amenities
                  </a>
                </div>
                <div className="col-md-6 col-lg-3">
                  <h6>Address</h6>
                  <a href="#" className="d-block pt-2 text-decoration-none">
                    Muzamil Center, Main Shehbaz Town,<br></br> Quetta, Pakistan
                  </a>
                </div>
                <div className="col-md-6 col-lg-3">
                  <h6>Contact Us</h6>
                  <p className="text-white m-0 pt-2">Naveed</p>
                  <a
                    href="tel:0333 3186789"
                    className="d-block text-decoration-none"
                  >
                    0333 3186789
                  </a>
                  <p className="text-white m-0 pt-2">Danial</p>
                  <a
                    href="tel:03322343420"
                    className="d-block text-decoration-none"
                  >
                    0332 2343420
                  </a>
                </div>
              </div>
            </div>
            <hr className="text-white" />
            <div className="row py-3 footer-bottom">
              <div className="col-12 col-sm-6 col-md-6">
                <span className="text-white">
                  © OLX Property 2023. All Rights Reserved
                </span>
              </div>
              <div className="col-12 col-sm-6 col-md-6 text-sm-center text-lg-end">
                <span className="text-white">
                  Developed by:{" "}
                  <a
                    target="_blank"
                    href="https://atrule.com/"
                    className="text-white"
                  >
                    Atrule technologies
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
